@import '../styles/defaults';

.cookies {
    &--popup {
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: auto;
        bottom: 0;
        background: $cookies-background;

        @media (max-width: 400px) {
            height: auto;
        }

        @media (max-width: 600px) {
            height: auto;
        }

        @media (max-width: 800px) {
            height: auto;
        }

        &__content {
            color: #000000;
            padding: 15px 10%;

            @media (max-width: 400px) {
                margin-top: auto;
            }

            @media (max-width: 600px) {
                margin-top: auto;
            }

            @media (max-width: 800px) {
                margin-top: auto;
            }

            a {
                color: #333333;
                font-weight: bold;

                &:hover {
                    color: #000000;
                }
            }
        }

        &__text {
            margin-bottom: 0px;
            margin-top: 0px;
            padding-right: 20px;
        }

        &__button {
            width: auto !important;

            @media screen and (max-width: 992px) {
                margin-top: 10px;
            }

            a {
                white-space: nowrap;
                margin-right: 10px;
                line-height: 32px;
                text-decoration: underline;
                font-weight: 400;
            }

            .ant-btn-primary {
                background-color: $cookies-btn-bg;

                &:hover {
                    background-color: $cookies-btn-bg-hover;
                }
            }
        }
    }

    .ant-row {
        @media screen and (min-width: 992px) {
            flex-wrap: nowrap;
        }

        .ant-col {
            display: flex;
        }
    }
}