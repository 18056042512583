@import '../styles/defaults';

.footer {
    padding: 20px;
    background: $footer-background !important;
    text-align: center;
    color: $footer-color !important;
    left: 0;
    bottom: 0;
    right: 0;
    p {
        margin: 0;
    }
    a {
        color: $footer-link-color;
        font-weight: bold;
        &:hover {
            color: $footer-link-color-over;
        }
    }
}
